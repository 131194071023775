import React from 'react'

const headingClasses = `
    font-sans
    font-bold
    text-green-3
    tracking-wide
    text-lg
    uppercase
    text-center
    lg:text-left
  `

const SubsectionHeading = ({ children }) => (
  <h3 className={headingClasses}>{children}</h3>
)

export default SubsectionHeading
