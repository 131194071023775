import React from 'react'
import styled from 'styled-components'
import EventPanel from '../../components/Templates/EventPanel'
import PersonCard from '../../components/General/Cards/PersonCard'
import FourUp from '../../components/Layouts/FourUp'
import ScriptEmbed from '../../components/General/ScriptEmbed'

import permissionless01 from '../../images/pageAssets/tech-permissionless.jpg'

const embed = `
<script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/shell.js"></script>
<script>
 hbspt.forms.create({
  portalId: "5068065",
  formId: "3b41a988-613c-4979-98ab-5f4ea0c27f4a"
});
</script>`

const teamMembers = [
  {
    name: 'Janet Wu (Moderator)',
    title: 'Bloomberg',
    modalDesc:
      "<p>Janet is a multi-media journalist and adjunct professor at Emerson College.</p><p>An Op-Ed contributor to The Boston Globe, her work has also been published in The New York Times and the upcoming 2018 'Norton Sampler of Essays.'</p> <p>She graduated from Yale with a dual major in Philosophy and Psychology and earned a master's degree in Journalism and International Affairs at Columbia University.</p><p>She is a founding member of SheGives, a philanthropic organization of women. She sits on boards for the New England Conservatory of Music, Franciscan Children's Hospital, The American Heart Association of Boston, Homestart, and The Max Warburg Courage Curriculum. </p><p>She was named the 2015 New England Girls Scouts 'Leading Woman of the Year' and delivered a 2016 TEDX Cambridge Talk at the Boston Opera House.</p>",
    image: {
      src: '/assets/bbw/photos/janet-wu.jpg',
      alt: 'janet wi',
      copyright: '',
    },
  },
  false,
  {
    name: 'Jim Cunha',
    title:
      '<p>Senior Vice President of Secure Payments and FinTech,</p><p>Federal Reserve Bank of Boston</p>',
    modalDesc:
      "<p>Jim Cunha is the Senior Vice President of Secure Payments and FinTech at the Federal Reserve Bank of Boston.</p><p>Cunha leads the Federal Reserve’s Secure Payment efforts, which seeks to improve the safety, security, and resiliency of the U.S. payments system.</p><p>Cunha is also spearheading the Boston Fed’s efforts to study, explore, and engage with distributed ledger technology, or blockchain, to determine applicability, potential benefits, and risks for internal and external uses. He is specifically focused on the evolution of the technology and its potential impact on the financial services sector.  He is also responsible for the Bank’s digital currency research efforts.</p><p>Cunha is also responsible for the Mobile Payment Industry Workgroup, which is a collaborative industry effort to study and eliminate barriers to the adoption of mobile technology in payments.</p><p>Cunha has worked at the Boston Fed since 1984. Prior to that, he worked at Fleet National Bank. He holds a bachelor’s in accounting and philosophy from Northeastern University and a bachelor’s in computer science from Rhode Island College.</p><p>Cunha serves as a member of the board of trustees of the Justice Resource Institute, a social justice nonprofit; is an ex-officio member of the board of directors at New England ACH Association, a payments education nonprofit; and was the former chairman of the board of Children's Friend and Family Services of Salem, Mass.</p>",
    image: {
      src: '/assets/bbw/photos/jim-cunha.jpg',
      alt: 'jim cunha',
      copyright: '',
    },
  },
  {
    name: 'Terrence Dempsey',
    title: '<p>Fidelity Digital Assets,</p><p>Fidelity</p>',
    modalDesc:
      '<p>Terrence Dempsey is Product Manager at Fidelity Digital Assets, which provides enterprise-grade digital asset solutions for institutional investors.</p><p>Prior to joining Fidelity Investments, Terrence spent nearly 5 years as the operations lead for the Bitcoin Investment Trust (GBTC) at Digital Currency Group’s asset management arm, Grayscale Investments. Terrence has also spent time in various trading and capital markets roles at SecondMarket prior to its sale to Nasdaq.</p><p>Terrence holds a BS in Business Administration with a concentration in Finance from Marist College.</p>',
    image: {
      src: '/assets/bbw/photos/terrence-dempsey.jpg',
      alt: 'terrence dempsey',
      copyright: '',
    },
  },
  {
    name: 'Steve Kokinos',
    title: '<p>CEO,</p><p>Algorand</p>',
    modalDesc:
      '<p>Steve is the CEO of Algorand and responsible for overall business vision and strategy.</p><p>Steve is a serial entrepreneur, most recently co-founder and Executive Chairman of Fuze, where he was responsible for corporate strategy. Under his direction, the company transformed the way enterprises view collaboration as a key driver of business visibility, process improvement, and results. Today, Fuze has over 700 employees servicing over 1500 enterprise customers around the world.</p><p>Prior to Fuze, Steve was co-founder of BladeLogic, Inc., a recognized leader in the data center automation market with a Fortune 500 client list including GE, Time Warner, Microsoft, Cable & Wireless, Walmart, and Sprint, among others. Bladelogic held a successful IPO and was later acquired by BMC Software for ~$800M.</p><p>Prior to BladeLogic, Steve was co-founder and CEO of Web Yes, Inc., an early market leader in the Web hosting and application service provider arena that provided infrastructure services for Sun Microsystems, Netscape, and Lycos,  among others. Web Yes was acquired by Breakaway Solutions in 1999.</p>',
    image: {
      src: '/assets/bbw/photos/steve-kokinos.jpg',
      alt: 'steve kokinos',
      copyright: '',
    },
  },
]

const MembersWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 930px;
  margin: 0 auto;
`

const Break = styled.div`
  flex-basis: 100%;
  height: 0;
`

const Members = () => (
  <MembersWrap>
    {teamMembers.map((person, i) => {
      return person ? (
        <div key={'member-' + i} className="p-2 md:w-1/3">
          <PersonCard {...person} />
        </div>
      ) : (
        <Break />
      )
    })}
  </MembersWrap>
)

const data = {
  seo: {
    title: 'Boston Blockchain Week',
    description:
      "Join us at Algorand's new offices for an evening of networking and conversation around what will define blockchain in 2020 & beyond.",
    keywords: [
      'blockchain technology',
      'blockchain platform',
      'blockchain network',
    ],
  },
  mantelData: {
    title: 'ALGORAND KICKS OFF BOSTON BLOCKCHAIN WEEK',
    crumbs: [
      {
        path: '',
        text: '',
      },
      {
        text: '',
      },
    ],
    description:
      "<p>Join us at Algorand's new offices for an evening of networking and <br> conversation around what will define blockchain in 2020 & beyond.</p><img alt='banner' src='/assets/bbw/banner-top.jpg'>",
  },
  sections: [
    {
      title: 'Details',
      subtitle: '',
      subsections: [
        {
          type: 'content',
          position: 'left',
          title: '',
          content: `
            <div class="mb-2 uppercase text-center font-bold text-dark-grey-2">
            where
            </div>
            <div class="mb-4 text-center text-dark-grey-2"> 399 Boylston Street, Boston, MA</div>
            <div class="mb-4 uppercase text-center font-bold text-dark-grey-2"> when </div>
            <div class="mb-4 text-center text-dark-grey-2">Monday, March 9, 2020</div>
            <div class="mb-4 text-center text-dark-grey-2">4:00-8:00PM</div>
          `,
        },
        {
          type: 'content',
          position: 'right',
          title: '',
          content: `
            <div class="mb-4 uppercase text-center font-bold text-dark-grey-2">
            agenda
            </div>
            <div class="mb-4 text-center text-dark-grey-2">4:00 - 4:30PM | Doors Open & Networking</div>
            <div class="mb-4 text-center text-dark-grey-2">4:30 - 4:45PM | Opening Remarks </div>
            <div class="mb-4 text-center text-dark-grey-2">4:45 - 5:30PM | Panel</div>
            <div class="mb-4 text-center text-dark-grey-2">5:30 - 8:00PM | Networking</div>
          `,
        },
      ],
    },
    {
      title: 'Panel',
      fullWidth: true,
      subsections: [
        {
          type: 'component',
          position: 'center',
          title: 'What Will Define Blockchain in 2020 and Beyond?',
          content: <Members />,
        },
      ],
    },
    {
      title: 'Registration Closed',
      fullWidth: true,
      subtitle: '',
      subsections: [
        {
          type: 'content',
          position: 'center',
          content: `
            <div class="text-center">For inquiries about space, please email <a href="mailto:contact@algorandtechnologies.com">contact@algorandtechnologies.com</a></div>
          `,
        },
      ],
    },
  ],
}

const Page = () => <EventPanel {...data} />

export default Page
