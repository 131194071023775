import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'

const ContentInfo = styled.div`
  ${ tw`
    font-sans
    pt-1
    text-dark-grey-2
  ` }

  h4 {
    ${ tw`
      font-bold
      leading-normal
      mb-3
    ` }
  }

  p {
    ${ tw`
      text-base
      font-light
      leading-normal
      mb-3
    ` }
  }

  button {
    ${ tw`
      mx-0
      mb-3
    ` }
  }

  ul {
    ${ tw`
      text-base
      leading-relaxed
      mb-3
    ` }
  }

  li {
    ${ tw`
      font-light
      text-base
      leading-relaxed
      pl-2
      mb-0
    ` }
  }

  a {
    ${ tw`
      w-auto
    ` }
  }
`

const Content = ({ content }) => (
  <ContentInfo dangerouslySetInnerHTML={{ __html: content }} />
)

export default Content
