import React from 'react'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import classNames from 'classnames'
import { rem, sentenceToKebabCase } from '../../../utils'

// components
import PageWrap from '../../Layouts/Global/PageWrap'
import Mantel from '../../General/Mantel'
import SectionIntro from '../../General/SectionIntro'
import SubsectionHeading from '../../General/Headings/SubsectionHeading'
import Content from '../../General/Content'
import ImageHeadingCard from '../../General/Cards/ImageHeadingCard'
import TabNav from '../../General/TabNav'
import Table from '../../General/Table'
import Button from '../../General/Buttons/Button'

const SectionPanelContainer = styled.div`
  ${tw`
    w-full
    max-w-lg
    mx-auto
    lg:flex
    lg:max-w-none
  `}
`

const SectionContainer = styled.div`
  ${tw`
    px-2
    mx-auto
  `}

  max-width: ${rem(1150)};
`

const SectionPanel = styled.div`
  overflow-wrap: break-word;
  @media (min-width: 992px) {
    width: ${(props) => (props.full ? `100%` : `50%`)};
  }
`

const sectionId = (section) =>
  section.id ? section.id.toLowerCase() : sentenceToKebabCase(section.title)

const TechSubsectionContent = ({ title, content }) => (
  <div className="pt-4">
    {title && <SubsectionHeading>{title}</SubsectionHeading>}
    <Content content={content} />
  </div>
)

const TechSubsectionImage = ({ image, heading }) => (
  <ImageHeadingCard image={image} heading={heading} />
)

const TechSubsectionComponent = ({ title, content }) => (
  <>
    <div className="font-sans text-dark-grey-2 mb-4 text-center text-lg">
      {title}
    </div>
    <div>{content}</div>
  </>
)

const techSubsections = {
  content: TechSubsectionContent,
  image: TechSubsectionImage,
  component: TechSubsectionComponent,
}

const techSubsectionPosition = {
  left: 'left',
  center: 'center',
  right: 'right',
}

const TechSection = ({
  id,
  title,
  fullWidth = false,
  subtitle,
  subsections,
  table,
  button,
  mb = 'mb-10',
}) => {
  return (
    <SectionContainer id={id} className={mb}>
      <div className="mx-auto max-w-lg">
        <SectionIntro title={title} subtitle={subtitle} thiccHeading />
      </div>
      <SectionPanelContainer>
        {subsections &&
          Object.values(techSubsectionPosition).map((position) => {
            const items = subsections.filter(
              (subsection) => subsection.position === position
            )
            return (
              items.length != 0 && (
                <SectionPanel
                  key={position}
                  full={fullWidth}
                  className={classNames({
                    'lg:pr-3': position === techSubsectionPosition.left,
                    'lg:p-3': position === techSubsectionPosition.center,
                    'lg:pl-3': position === techSubsectionPosition.right,
                  })}
                >
                  {items.map((subsection, index) => {
                    const Component = techSubsections[subsection.type]
                    return <Component key={index} {...subsection} />
                  })}
                </SectionPanel>
              )
            )
          })}
      </SectionPanelContainer>
      {table && <Table {...table} />}
      {button && (
        <div className="flex justify-center">
          <a className="no-underline" href={button.url}>
            <Button>{button.text}</Button>
          </a>
        </div>
      )}
    </SectionContainer>
  )
}

const ContentList = ({
  headerProps,
  seo,
  footerProps,
  mantelData,
  sections,
}) => {
  const tabs =
    sections &&
    sections.map((section) => ({
      active: false,
      anchor: sectionId(section),
      text: section.title,
    }))

  return (
    <PageWrap
      headerProps={headerProps}
      footerProps={footerProps}
      bg={2}
      seo={seo}
    >
      <Mantel {...mantelData} />
      <TabNav items={tabs} />
      {sections &&
        sections.map((section, index) => {
          const id = sectionId(section)
          return (
            <TechSection key={id || `section${index}`} id={id} {...section} />
          )
        })}
    </PageWrap>
  )
}

export default ContentList
