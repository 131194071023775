import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { rem } from '../../../utils/index'

const ImageCardWrap = styled.article`
  ${ tw`
    bg-cover
    mt-4
  ` }
  box-sizing: border-box;
  background-image: url(${ props => props.background });
  background-repeat: no-repeat;
  background-position: center center;
  height: ${ rem(400) };
`

const ImageHeadingCard = ({ image, heading }) => {
  const overlay = `
    flex
    content-end
    h-full
    w-full
    justify-center
    items-center
    p-4 md:p-8
  `

  const taglineClasses = `
    text-2xl
    text-left
    text-white
    font-sans
    font-normal
    leading-normal
  `

  return (
    <ImageCardWrap background={image}>
      <div className={ overlay }>
        <div className={ taglineClasses } dangerouslySetInnerHTML={{ __html: heading }}></div>
      </div>
    </ImageCardWrap>
  )
}

export default ImageHeadingCard
