import React from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import tw from 'tailwind.macro'

// components
import SubsectionHeading from '../Headings/SubsectionHeading'

const ColumnListContainer = styled.div`
  ul {
    ${tw`
      my-2
      mx-4
      p-0
      list-none
    `}
  }
  li {
    ${tw`
      flex
      items-center
      mb-1px
      text-medium
      leading-normal
      text-dark-grey-2
    `}

    &:before {
      content: '►';
      ${tw`
        text-xs
        text-green-3
        mr-1
      `}
    }
  }
`

const ContentCard = ({ title, columns }) => {
  const containerClasses = `
    mt-4
    lg:mt-2
    mx-auto
    bg-white
    lg:flex
    w-full
    border
    border-grey
    text-charcoal
    max-w-lg
    lg:max-w-none
  `

  const columnClasses = `
    flex
    flex-col
    flex-1
    border-grey
  `

  const columnTitleClasses = `
    font-sans
    text-dark-grey-2
    text-sm
    bg-light-grey-2
    px-2
    py-1
    uppercase
  `

  const columnListContainerClasses = `
    my-2
    mx-4
    p-0
    list-none
  `

  const columnListItemClasses = `
    flex
    items-center
    mb-1px
  `

  const columnListItemArrowClasses = `
    text-xs
    text-green-3
    mr-1
  `

  const columnListItemText = `
    text-medium
    leading-normal
    text-dark-grey-2
  `

  return (
    <div className="mb-4">
      {title && <SubsectionHeading>{title}</SubsectionHeading>}
      {columns && (
        <div className={containerClasses}>
          {columns.map((column, index) => (
            <div
              key={index}
              className={classNames(columnClasses, {
                'lg:border-r-0 lg:border-b-0': index === columns.length - 1,
                'lg:border-r lg:border-b-0 border-b':
                  index < columns.length - 1,
              })}
            >
              {column.title && (
                <span className={columnTitleClasses}>{column.title}</span>
              )}
              {column.list && (
                <ColumnListContainer
                  dangerouslySetInnerHTML={{ __html: column.list }}
                />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default ContentCard
